<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Add Bank Account</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="accountName" label="Account Name" v-model="account.accountName" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="accountNumber" label="Account Number" v-model="account.accountNumber" />
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddBankAccountDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    error: null,
    account: {
      accountName: '',
      accountNumber: '',
      accountType: 'ASSET'
    }
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    save () {
      fetch('/api/accounting/bank-accounts', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.account)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            throw new Error('Error saving bank account')
          }
        })
        .catch(error => {
          this.error = error.message
        })
    }
  }
}
</script>
